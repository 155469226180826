<template>
  <div>
    <promotion-filters
      :title-filter="titleFilter"
      :type-filter.sync="typeFilter"
      :type-options="typeOptions"
      :language-filter.sync="languageFilter"
      :language-options="languageOptions"
      :promotion-status-filter.sync="promotionStatusFilter"
      :promotion-status-options="promotionStatusOptions"
      :status-filter.sync="statusFilter"
      :status-options="statusOptions"
      :created-by-filter.sync="createdByFilter"
      :fromdate-filter.sync="fromdateFilter"
      :todate-filter.sync="todateFilter"
    />
    <b-alert variant="primary" show dismissible>
      <div class="alert-body">Datas below is demo</div>
    </b-alert>
    <b-card no-body>
      <div class="m-2">
        <b-row>
          <b-col cols="12">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="primary" @click="showPromotionActionModal()">
                <span class="text-nowrap">
                  <feather-icon icon="PlusIcon" /> {{ $t("New") }}
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="refPromotionTable"
        class="position-relative table-white-space mb-0"
        :items="promotionLists"
        responsive
        :fields="tableColumns"
        primary-key="index"
        show-empty
        empty-text="No matching records found"
      >
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + data.index + 1 }}
        </template>
        <template #cell(title)="data">
          <b-link
            class="cursor-pointer text-white"
            @click="showPromotionActionModal(data.item)"
          >
            {{ data.item.title }}
          </b-link>
        </template>

        <template #cell(image)="data">
          <b-img :src="data.item.image" fluid rounded></b-img>
        </template>

        <template #cell(status)="data">
          <div :class="`text-capitalize align-items-center d-flex`">
            <span
              :class="`dot bg-${resolveStatus(data.item.status).variant}`"
            />{{ resolveStatus(data.item.status).label }}
          </div>
        </template>

        <template #cell(action)="data">
          <b-link
            class="font-weight-bold text-nowrap"
            @click="showPromotionActionModal(data.item)"
          >
            Edit
          </b-link>
        </template>
      </b-table>
      <div class="p-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPromotion"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <!-- Promotion Modal -->
    <add-promotion-modal :promotion-content.sync="promotionContent" />
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BAlert,
  BImg,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BTr,
  BTh,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { ref, watch, computed } from "@vue/composition-api";
import { numberFormat, formatDateTime } from "@core/utils/filter";
import { useToast } from "vue-toastification/composition";
import PromotionFilters from "./PromotionFilters.vue";

export default {
  components: {
    PromotionFilters,
    AddPromotionModal: () => import("./AddPromotionModal.vue"),
    BButton,
    BFormGroup,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BTr,
    BTh,
    BMedia,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BAlert,
    BImg,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      promotionId: null,
      promotionContent: null,
    };
  },
  methods: {
    showPromotionActionModal(data = null) {
      if (data) {
        this.promotionContent = data;
      }
      this.$bvModal.show("modal-action-promotion");
    },
  },
  setup() {
    const dataDemos = [
      {
        title: "test",
        image: "https://placeimg.com/150/100/any",
        type: "New member",
        language: "Tiếng việt",
        promotion_status: "Expired",
        status: 1,
        start_date: "09-14-2022 00:00:00",
        end_date: "09-20-2022 23:59:59",
        created_at: "04-22-2022 11:00:49",
        created_by: "demonwarlock",
        updated_at: "04-22-2022 11:01:33",
        updated_by: "demonwarlock",
      },
    ];
    const promotionLists = ref(dataDemos);
    const perPage = ref(20);
    const totalPromotion = ref(0);
    const currentPage = ref(1);
    const titleFilter = ref(null);
    const typeFilter = ref(null);
    const typeOptions = ref([]);
    const languageFilter = ref(null);
    const languageOptions = ref([]);
    const promotionStatusFilter = ref(null);
    const promotionStatusOptions = ref([]);
    const statusFilter = ref(null);
    const statusOptions = [
      { label: "Active", value: 1 },
      { label: "Inactive", value: 2 },
    ];
    const createdByFilter = ref(null);
    const fromdateFilter = ref(null);
    const todateFilter = ref(null);
    const refPromotionTable = ref(null);
    const tableColumns = [
      { key: "index", label: "#" },
      { key: "title", label: "Title" },
      { key: "image", label: "Image" },
      { key: "type", label: "Type" },
      { key: "language", label: "Language" },
      { key: "promotion_status", label: "Promotion status" },
      { key: "status", label: "Status" },
      { key: "start_date", label: "Start date" },
      { key: "end_date", label: "End date" },
      { key: "created_at", label: "Created at" },
      { key: "created_by", label: "Created by" },
      { key: "updated_at", label: "Updated at" },
      { key: "updated_by", label: "Updated by" },
      { key: "action", label: "Actions" },
    ];
    const dataMeta = computed(() => {
      const localItemsCount = refPromotionTable.value
        ? refPromotionTable.value.localItems.length
        : 0;
      return {
        from:
          perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalPromotion.value,
      };
    });
    const refetchData = () => {
      refPromotionTable.value.refresh();
    };

    watch(
      [
        currentPage,
        perPage,
        titleFilter,
        typeFilter,
        languageFilter,
        fromdateFilter,
        todateFilter,
        promotionStatusFilter,
        statusFilter,
        createdByFilter,
      ],
      () => {
        refetchData();
      }
    );

    const resolveStatus = (status) => {
      if (status == 1) return { label: "Active", variant: "success" };
      if (status == 2) return { label: "Inactive", variant: "secondary" };
      return { label: "None", variant: "secondary" };
    };
    return {
      tableColumns,
      perPage,
      currentPage,
      totalPromotion,
      dataMeta,
      refPromotionTable,

      promotionLists,
      refetchData,

      titleFilter,
      typeFilter,
      typeOptions,
      languageFilter,
      languageOptions,
      promotionStatusFilter,
      promotionStatusOptions,
      createdByFilter,
      fromdateFilter,
      todateFilter,
      resolveStatus,
      statusFilter,
      statusOptions,
      formatDateTime,
      numberFormat,
    };
  },
};
</script>