<template>
	<b-card no-body>
		<b-card-body>
			<b-row>
				<b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Title') }}</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="titleFilter"
						class="w-100"
						@input="(val) => $emit('update:titleFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>Type</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="typeFilter"
						:options="typeOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:typeFilter', val)"
					/>
				</b-col>
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Language')}}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="languageFilter"
						:options="languageOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:languageFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<label>{{ $t('Promotion status')}}</label>
					<v-select
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="promotionStatusFilter"
						:options="promotionStatusOptions"
						class="w-100"
						:reduce="val => val.value"
						@input="(val) => $emit('update:promotionStatusFilter', val)"
					/>
				</b-col>
                <b-col
					cols="12"
					md="4"
					class="mb-2"
				>
					<label>{{ $t('Created by') }}</label>
					<b-form-input
						:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
						:value="createdByFilter"
						class="w-100"
						@input="(val) => $emit('update:createdByFilter', val)"
					/>
				</b-col>
				<!-- From -->
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<b-form-group
						label="From Date"
						label-for="fromDate"
					>
						<flat-pickr
							id="fromDate"
							v-model="fromFilter"
							class="form-control"
							placeholder="YYYY-MM-DD"
							@on-change="changeFromDate"
						/>
					</b-form-group>
				</b-col>
				<!-- To -->
				<b-col
					cols="12"
					md="4"
					class="mb-md-0 mb-2"
				>
					<b-form-group
						label="To Date"
						label-for="toDate"
					>
						<flat-pickr
							id="toDate"
							v-model="toFilter"
							class="form-control"
							placeholder="YYYY-MM-DD"
							@on-change="changeToDate"
						/>
					</b-form-group>
				</b-col>
				<!-- action -->
				<b-col
					cols="12"
					class="d-flex justify-content-end"
				>
					<b-button
						variant="secondary"
						@click="resetFilter()"
					>
						{{ $t('Reset') }}
					</b-button>
					<b-button
						variant="primary"
						class="ml-2"
						@click="searchFilter()"
					>
						{{ $t('Search') }}
					</b-button>
				</b-col>
			</b-row>
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BFormDatepicker, BInputGroup, BInputGroupAppend, BCardText, BFormGroup, BFormInput, BCard, BCardHeader, BCardBody, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import 'flatpickr/dist/flatpickr.css'
export default {
	components: {
		BButton,
		BRow,
		BCol,
		BCard,
		BFormGroup,
		BFormInput,
		BCardHeader,
		BCardBody,
		BCardText,
		BFormDatepicker,
		BInputGroup,
		BInputGroupAppend,
		BFormInput,
		vSelect,
		flatPickr,
	},
	props: {
		titleFilter: {
			type: [String, null],
			default: null,
		},
		typeFilter: {
			type: [Number, null],
			default: null,
		},
        languageFilter: {
			type: [Number, null],
			default: null,
		},
        promotionStatusFilter: {
			type: [Number, null],
			default: null,
		},
		statusFilter: {
			type: [Number, null],
			default: null,
		},
        createdByFilter: {
			type: [String, null],
			default: null,
		},
		fromdateFilter: {
			type: [String, null],
			default: null,
		},
		todateFilter: {
			type: [String, null],
			default: null,
		},
        typeOptions: {
            type: [Array, null],
            default: null,
        },
		languageOptions: {
			type: Array,
			default: null,
		},
		statusOptions: {
			type: Array,
			default: null,
		},
        promotionStatusOptions: {
            type: Array,
            default: null,
        }
	},
	methods: {
		changeFromDate() {
			this.$emit('update:fromdateFilter', this.fromFilter)
		},
		changeToDate() {
			this.$emit('update:todateFilter', this.toFilter)
		}
	},
	setup(props, { emit }) {
		const fromFilter = ref(null)
		const toFilter = ref(null)
		const resetFilter = () => {
			emit('update:usernameFilter', null)
			emit('update:productFilter', null)
			emit('update:bonusCodeFilter', null)
			emit('update:isVoidFilter', null)
			emit('update:fromdateFilter', null)
			emit('update:todateFilter', null)
			fromFilter.value = null
			toFilter.value = null
		}

		const searchFilter = () => {
			alert('waiting API ....')
		}
		return {
			resetFilter,
			searchFilter,
			fromFilter,
			toFilter,
		}
	}
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
